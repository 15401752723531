import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from '@angular/core';
import {ROUTES} from './vertical-menu-routes.config';
import {HROUTES} from '../horizontal-menu/navigation-routes.config';

import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {customAnimations} from '../animations/custom-animations';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ConfigService} from '../services/config.service';
import {Subscription} from 'rxjs';
import {LayoutService} from '../services/layout.service';
import {AuthService} from '../auth/auth.service';
import {LocalStorageService} from '../storage/local-storage.service';
import {PULSERIGHT_ROUTES} from './vertical-pulseright-menu-routes.config';
import {InterfaceModal} from './OrgGroup.model';

const moment = require('moment')

@Component({
  selector: 'app-sidebar',
  templateUrl: './vertical-menu.component.html',
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[] = [];
  level = 0;
  logoUrl = 'assets/img/logo.png';
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;
  userData: any = {
    auth: {
      data: {}
    },
    organizationGroupList: [],
    doctorList: [],
    SelectedOrg: '',
    SelectedOrgGrp: '',
    patientList: [],
    StaffList: [],
    serviceList: [],
    dashboard: {},
    managerDashboard: {},
    timeOffList: [],
    overTime: [],
  };
  orgDetails: any;
  orgGrpList: InterfaceModal[] = [];
  OrgGroup: any;
  modules: any;
  selectedPharmacy: any;
  pharmacyList = [];

  constructor(
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    public authService: AuthService,
    private deviceService: DeviceDetectorService,
    private storage: LocalStorageService
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }


  async ngOnInit() {
    this.OrgGroup = JSON.parse(localStorage.getItem('organizationGroup'));
    this.translate.use(localStorage.getItem('language') || 'en');
    const menus = JSON.parse(JSON.stringify(PULSERIGHT_ROUTES));
    await this.storage.getData('pulse-right').then(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    // Watch storage for changes
    this.storage.watchStorage().subscribe(res => {
      if (res != null || res) {
        this.userData = res;
      }
    });
    // this.authService.languageChange.subscribe((data) => {
    //   this.translate.use(data);
    // })
    if (window.location.pathname !== '/booking') {
      this.orgDetails = this.OrgGroup.data.organization.find(org => org._id === this.userData.SelectedOrg);
      localStorage.setItem('organization', this.userData.SelectedOrg);
      if (this.userData.auth.data?.userType === 'Pharmacy Admin') {
        this.authService.pharmacySubject.subscribe((pharmacyId) => {
          this.authService.fetchPharmacy(this.userData, pharmacyId).then((pharmacy: any) => {
            this.orgDetails = pharmacy;
          });
        })
        this.loadPharmacy();
      }
      this.authService.menuLoader.subscribe(async (result: any) => {
        if (this.userData.auth.data?.userType === 'Admin') {
          if (JSON.parse(localStorage.getItem('OrganizationGroupList'))) {
            this.orgGrpList = JSON.parse(localStorage.getItem('OrganizationGroupList'));
            const org = this.orgGrpList.find(orgGrp => orgGrp._id === localStorage.getItem('orgGroupId')).organization
              .find(organization => organization._id === localStorage.getItem('organization'));
            this.authService.fetch_organization_Id(this.userData, org._id).then((response: any) => {
              this.orgDetails = response;
            })
          }
        }
        if (result === 'OrgDetails') {
          await this.setUpModules(menus);
        } else {
          this.orgDetails = this.userData.auth.data.organization[0];
        }
      });
    }
  }

  loadPharmacy() {
    this.authService.fetchPharmacies(this.userData, this.userData.SelectedOrg, 'organization').then((resp: any) => {
      this.pharmacyList = resp.results.length > 0 ? resp.results.filter((data) => {
        return !data.deleted;
      }) : [];
      this.orgDetails = this.pharmacyList.find(pharmacy => {
        return pharmacy.organization.find(orgId => {
          return this.userData.SelectedOrg === orgId;
        });
      });
    });
  }

  async setUpModules(menus) {
    if (window.location.pathname !== '/booking') {
      if (this.userData.auth.data?.role === 'Super Admin') {
        await this.loadMenus(menus);
      } else if (this.userData.auth.data?.userType === 'Pharmacy Admin') {
        await this.loadMenus(menus);
      } else if (this.userData.auth.data?.role === 'Admin' || this.userData.auth.data?.role === 'User') {
        const organization = this.userData.auth.data?.organization.find((org: any) => {
          return org.organizationId._id === this.userData.SelectedOrg;
        });
        this.orgDetails = organization.organizationId;
        const temp = [];
        for (const tempElement of organization.organizationId.modulesEnabled) {
          temp.push(tempElement.name);
        }
        const result2 = menus.filter((r) => temp.includes(r.title));
        await this.loadMenus(result2);
      }
    }
  }

  async loadMenus(ROUTES) {
    if (this.userData) {
      const role = this.userData.auth.data.userType;
      const menuData = ROUTES.filter((item) => {
        if (item.category.includes(role)) {
          if (item.submenu.length > 0) {
            const itemSubmenu = item.submenu.filter((submenu) => {
              return submenu.category.includes(role);
            });
            item.submenu = itemSubmenu;
          }
          return item;
        }
      });
      this.menuItems = menuData;
    }
  }


  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === 'Side') {
          this.collapseSidebar = collapse;
        }
      });

  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      this.innerWidth = event.target.innerWidth;
      this.loadLayout();
    }).bind(this), 500);
  }

  loadLayout() {

    if (this.config.layout.menuPosition === 'Top') { // Horizontal Menu
      if (this.innerWidth < 1200) { // Screen size < 1200
        this.menuItems = HROUTES;
      }
    } else if (this.config.layout.menuPosition === 'Side') { // Vertical Menu{
      // this.menuItems = ROUTES;
    }


    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    } else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    const conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({layout: conf.layout});

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent('HTMLEvents');
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    } else {
      this.perfectScrollbarEnable = true;
    }

  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

}
